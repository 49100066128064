@import './dark.less';

html, body #root {
  height: 100%;
  overflow: hidden;
}

.loginContainer {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #1f1f1f;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.resizeWrapper {
  width: 100%;
  height: 100%;
}

text {
  fill: @primary-color;
}

loginElements {
  margin: auto;
  width: 50%;
}

.logo {
  margin: 0px;
  width: 290px;
  background: #1f1f1f;
  font-weight: bold;
}

img.logo {
  margin: 40px;
  margin-bottom: 20px;
  width: 230px;
}

.ant-header {
  overflow: hidden;
  display: table-row;
  height: 60px;
}

.ant-btn {
  margin: 5px;
}

.ant-breadcrumb {
  margin: 16px 0;
  padding: 0px 24px 8px;
  width: 60%;
  float: left;
  display: table-cell;
}

.ant-userinfo {
  margin: 16px 0px;
  padding: 0px 24px 8px;
  width: 40%;
  float: right;
  text-align: right;
  display: table-cell;
}

.ant-layout-content {
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 340px;
  right: 0;
}

p.log {
  padding: 0px;
  margin-top: 0;
  margin-bottom: 0;
}

.menu {
  height: 100%;
  border-right: 0;
  width: 322px;
}

.site-layout-background {
  background: #1f1f1f;
}

.ant-layout-content {
  overflow: auto;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

table.logTable {
  table-layout: fixed;
  width: 100%;
  border: none;
}

table.logTable tbody tr {
  padding: 0px;
  line-height: 1.1em;
}

table.logTable pre {
  margin: 0.1em 0 0.1em 0;
}

.logLevel_error {
  color: red;
  font-weight: bold;
}

.logLevel_warn {
  color: yellow;
  font-weight: bold;
}

.logLevel_info {
  color: white;
  font-weight: bold;
}

.logLevel_banner {
  color: white;
  font-weight: bold;
}

.logLevel_http {
  color: gray;
}

.logLevel_verbose {
  color: lightblue;
  font-weight: bold;
}

.logLevel_debug {
  color: gray;
  font-weight: bold;
}

.logLevel_silly {
  color: yellow;
}

.logLevel_highlight {
  color: white;
  font-weight: bold;
}

